import { Meta, Layout, Section } from "~/ui";

import errorImg from "~/assets/images/about.png";

export default function Error(): JSX.Element {
  return (
    <Layout>
      <Section
        bgColor="creamGradient"
        baseBgColorTop="primary"
        roundedCorners={{ "top-left": true }}
      >
        <div className="row">
          <div className="col-12 col-lg-8 mb-4 mb-lg-0">
            <h1 className="d-flex flex-column align-self-center text-primary mb-4">
              <span className="display-1">Oh no.</span>
              <span>Something went wrong while processing your request.</span>
            </h1>
          </div>
          <div className="col-12 col-lg-4 text-center mb-4 mb-lg-0">
            <img
              src={errorImg}
              alt="Volunteers packing boxes and a volunteer painting a fence"
              className="img-fluid mt-lg-n5"
              width={364}
              height={735}
            />
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export const Head = () => {
  return <Meta title="Error" description="Something went wrong." />;
};
